import axios from "axios";
import { verifyToken } from "../../../utils/Funciones";
const API_URL = `https://kingstorebolivia.com:8443/api/history/`;


const getBypage = async (query) => {
    const response = await axios.get(API_URL + `historiesbypage${query}`, {
        headers: {
            Authorization: 'Bearer ' + verifyToken('adminToken')
        },
    });
    return response.data;
};

const getLast10Ventas = async () => {
    const response = await axios.get(API_URL + `last10ventas`);
    return response.data;
};

const deleteAll = async () => {
    const response = await axios.delete(API_URL + 'history', {
        headers: {
            Authorization: 'Bearer ' + verifyToken('adminToken')
        },
    });
    return response.data;
};

const historyService = {
    getBypage,
    getLast10Ventas,
    deleteAll
};

export default historyService;
