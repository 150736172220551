import axios from "axios";
import { verifyToken } from "../../../utils/Funciones";
const API_URL = `https://kingstorebolivia.com:8443/api/payment/`;

const getBypage = async (query) => {
    const response = await axios.get(API_URL + `paymentsbypage${query}`, {
        headers: {
            Authorization: 'Bearer ' + verifyToken('adminToken')
        },
    });
    return response.data;
};

const getByPageByUserId = async (query) => {
    const response = await axios.get(API_URL + `paymentsbyuserId${query}`, {
        headers: {
            Authorization: 'Bearer ' + verifyToken('userToken')
        },
    });
    return response.data;
};

const contador = async () => {
    const response = await axios.get(API_URL + `contador`, {
        headers: {
            Authorization: 'Bearer ' + verifyToken('adminToken')
        },
    });
    return response.data;
};

const createYapePayment = async (formData) => {
    const response = await axios.post(API_URL + 'yape', formData, {
        headers: {
            Authorization: 'Bearer ' + verifyToken('userToken')
        },
    });
    return response.data;
};

const anularPayment = async (formData) => {
    const response = await axios.patch(API_URL + 'anularpayment', formData, {
        headers: {
            Authorization: 'Bearer ' + verifyToken('adminToken')
        },
    });
    return response.data;
};

const createMercadoPagoPayment = async (formData) => {
    const response = await axios.post(API_URL + 'mercadopago', formData, {
        headers: {
            Authorization: 'Bearer ' + verifyToken('userToken')
        },
    });
    return response.data;
};

const createBinancePayment = async (formData) => {
    const response = await axios.post(API_URL + 'binance', formData, {
        headers: {
            Authorization: 'Bearer ' + verifyToken('userToken')
        },
    });
    return response.data;
};

const createIziPayPayment = async (formData) => {
    const response = await axios.post(API_URL + 'izipay', formData, {
        headers: {
            Authorization: 'Bearer ' + verifyToken('userToken')
        },
    });
    return response.data;
};

const verifyIziPayPayment = async (formData) => {
    const response = await axios.post(API_URL + 'verifyizipay', formData, {
        headers: {
            Authorization: 'Bearer ' + verifyToken('userToken')
        },
    });
    return response.data;
};

const paymentService = {
    getBypage,
    getByPageByUserId,
    contador,
    createYapePayment,
    anularPayment,
    createMercadoPagoPayment,
    createBinancePayment,
    createIziPayPayment,
    verifyIziPayPayment
};

export default paymentService;
